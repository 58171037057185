:root {
  --bg-color: #FAFAFA;
  --fg-color: #383838;
  --heading-color: #383838;
  --subheading-color: #686868;
  --accent-color: #49739f;
  --project-bg-color: #FAFAFA;
  --project-border-color: #CACACA;
  --tag-background: #E0E0E0;
  --tag-color: #383838;
}

body.dark-mode {
  --bg-color: #212121;
  --fg-color: #B8B8B8;
  --heading-color: #B8B8B8;
  --subheading-color: #9E9E9E;
  --accent-color: #49739f;
  --project-bg-color: #212121;
  --project-border-color: #616161;
  --tag-background: #424242;
  --tag-color: #B8B8B8;
}

body {
  font-family: 'Segoe UI', sans-serif;
  color: var(--fg-color);
  background-color: var(--bg-color);
}

@media (max-width: 767px) {
  body {
    font-size: 18px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h2 {
  color: var(--heading-color);
  border-bottom: 2px solid var(--heading-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h3 {
  color: var(--subheading-color);
  margin-bottom: 10px;
}

a {
  color: var(--heading-color);
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
}

.section {
  background-color: var(--project-bg-color);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 15px rgba(0,0,0,0.1);
}

.skills-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.skill-tag {
  display: inline-block;
  background-color: var(--tag-background);
  color: var(--tag-color);
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1em;
}

.skill-tag:first-child {
  margin-left: 0;
}

.job-entry {
  padding: 10px 0;
  margin: 10px 0;
  border-bottom: 1px solid var(--project-border-color);
}

.job-entry:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.link-tag {
  display: inline-block;
  background-color: var(--tag-background);
  color: var(--tag-color);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.link-tag:first-child {
  margin-left: 0;
}

.task-list {
  padding-left: 20px;
}

.task-item {
  margin-bottom: 10px;
}

.section.projects {
  padding: 20px;
}

.project {
  margin-bottom: 20px;
}

.project {
  padding: 10px 0;
  margin: 10px 0;
  border-bottom: 1px solid var(--project-border-color);
}

.project:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.projects h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.section.projects h3 a {
  color: var(--heading-color);
  text-decoration: underline;
}

.section.projects p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--fg-color);
}

.section.repositories {
  padding: 20px;
}

.repository {
  margin-bottom: 20px;
}

.repository {
  padding: 10px 0;
  margin: 10px 0;
  border-bottom: 1px solid var(--project-border-color);
}

.repository:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.section.repositories h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.section.repositories h3 a {
  color: var(--accent-color);
  text-decoration: none;
}

.section.repositories p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--fg-color);
}

.theme-switch {
  position: fixed;
  bottom: 5px;
  left: 15px;
}

.updates-and-insights-date {
  color: var(--subheading-color);
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: bold;
}

.updates-and-insights-title {
  margin-top: 5px;
}

.updates-and-insights-body {
  white-space: pre-line;
}

.pagination-controls {
  text-align: center; /* Center the pagination controls */
  margin-top: 20px; /* Add some space above the pagination controls */
}

.pagination-controls button {
  background-color: var(--tag-background); /* Use the tag background color for button background */
  color: var(--fg-color); /* Use the foreground color for button text */
  border: 1px solid var(--project-border-color); /* Add border to match the project border color */
  padding: 5px 10px; /* Smaller padding for smaller buttons */
  margin: 0 3px; /* Reduce space between buttons */
  border-radius: 3px; /* Slightly round the corners */
  cursor: pointer; /* Change cursor to pointer to indicate these are clickable */
  transition: background-color 0.3s; /* Add a transition for when the background color changes */
  font-size: 0.65rem; /* Reduce font size */
}

.pagination-controls button:hover {
  background-color: var(--subheading-color); /* Change background color on hover */
}

.pagination-controls button:disabled,
.pagination-controls button.active {
  background-color: var(--fg-color); /* Accent color for the active/disabled button */
  color: var(--bg-color); /* Background color for button text */
  cursor: default; /* Revert cursor to default for disabled buttons */
  border: none; /* No border for active/disabled buttons */
}

.navigation {
  width: 100%;
  background: var(--bg-color);
  padding: 20px 0;
  position: relative;
  z-index: 1000;
}

.hamburger {
  display: none; /* Hidden by default, shown on smaller screens */
  cursor: pointer;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.menu li {
  padding: 10px 20px;
}

.menu li a {
  text-decoration: none;
  color: var(--fg-color);
  transition: color 0.3s ease;
}

.menu li a:hover {
  text-decoration: underline;
}

/* For smaller screens */
@media (max-width: 767px) {
  .hamburger {
    display: block;
    margin-left: 20px;
  }

  .menu {
    display: none; /* Hide menu items by default */
    flex-direction: column;
    width: 100%;
    position: absolute;
    background: var(--bg-color);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .menu.open {
    display: flex; /* Show menu when open */
  }

  .menu li {
    text-align: center;
  }

  .menu li a {
    display: block;
    padding: 15px 20px;
  }
}

.top-to-btm {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 1;
  background-color: var(--bg-color);
  color: var(--fg-color);
  border: 1px solid var(--fg-color);
  border-radius: 25px;
  padding: 10px 15px;
  cursor: pointer;
  transition: opacity 0.4s ease, visibility 0.4s ease;
}

.top-to-btm.show {
  opacity: 1;
  visibility: visible;
}

.lightbulb-svg-glow {
  filter: drop-shadow(0 0 10px gold);
}